import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AboutPL from "../components/about/aboutPL"
import AboutENG from "../components/about/aboutENG"
import FBMessangerButton from "../components/buttons/FBmessangerButton"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "zuti.png" }) {
        childImageSharp {
          fluid(maxWidth: 443) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About" />

      <div className="aboutContent">
        <div className="zutiPhoto desktopOnly">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="Zuti" />
        </div>
        <div className="aboutInfo">
          <AboutPL />
          <AboutENG />
          <FBMessangerButton />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
