import React from "react"
import MessengerIcon from "../../images/icons/messenger.svg"

const FBMessangerButton = () => (
  <a className="aboutFbButton" href="https://m.me/zutigrafika">
    <button className="mobileOnly" href="m.me/zutigrafika">
      <MessengerIcon />
      Napisz do mnie!{" "}
    </button>
  </a>
)

export default FBMessangerButton
