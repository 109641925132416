import React from "react"

const AboutENG = () => (
  <div className="aboutENG">
    <h1>Hi!</h1>
    <p>
      My name is <strong>Gosia Zboina</strong> (until recently it was Zuterek),
      <br /> I am an <strong>architect and a graphic designer.</strong>
    </p>
    <p>
      I was born and brought up in <strong>Łódź</strong>, Poland, in a family of
      architects. It was my parents who have taught me to search aesthetics in
      every field of my surrounding. Since I was a child, instead of coloring a
      traditional coloring book, I was given building’s facades to play with and
      during numerous family trips I was learing to distinguish tinest details
      in visited budilings.
    </p>
    <p>
      I have studied Architecture in Łódź and in Montpellier (France) and
      Graphic Design in Fine Academy of Arts. On daily basis I design buildings
      and interiors while working as Zuti I try to mix my passion for
      architecture with the fun of making posters, visual identification and
      illustration.
    </p>
    <p>
      My inspirations are cities, people and various typography. In my works I
      try to create my own, not very serious, reality. I hope that thanks to my
      posters, some forgotten buidlings and pieces of art will gain a second
      life and become remembered as icons of polish design.
    </p>
    <p>
      <strong>
        Are you looking for a poster for your interior, illustration or you need
        a visual identification design for your company?
      </strong>
      <br /> I will be glad to work with you!
    </p>
    <p>
      <strong>
        Feel free to contact me:
        <br /> zutizuterful@gmail.com <br />
        +48 603 884 830
      </strong>
    </p>
  </div>
)

export default AboutENG
