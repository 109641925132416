import React from "react"

const AboutPL = () => (
  <div className="aboutPL">
    <h1>Cześć!</h1>
    <p>
      Nazywam się <strong>Gosia Zboina</strong> (do niedawna Zuterek),
      <br /> jestem <strong>architektem i grafikiem.</strong>
    </p>
    <p>
      Urodziłam się i wychowałam w <strong>Łodzi</strong>, w rodzinie
      architektów. To właśnie rodzice zarazili mnie pasją do nieustającego
      poszukiwania estetyki w naszym otoczeniu. Od dziecka zamiast klasycznych
      kolorowanek dostawałam elewacje budynków, a w trakcie wycieczek uczyłam
      się rozróżniać najdrobniejsze detale odwiedzanych zabytków.
    </p>
    <p>
      Studiowałam na Wydziale Architektury w Łodzi i w Montpellier oraz na
      Wydziale Grafiki Akademii Sztuk Pięknych. Na co dzień projektuję budynki i
      wnętrza a działając jako Zuti łączę zamiłowanie do architektury z frajdą
      tworzenia plakatów, indentyfikacji czy ilustracji.
    </p>
    <p>
      Inspirując się miastem, ludźmi i różnorodną typografią buduję swoją własną
      rzeczywistość, którą traktuję z lekkim przymrużeniem oka ;) Mam nadzieję,
      że dzięki moim plakatom zapomniane perełki polskiej architektury i designu
      zyskają drugie życie.
    </p>
    <p>
      <strong>
        Szukasz plakatu do wnętrza, ilustracji lub potrzebujesz projektu
        identyfikacji dla swojej firmy?
      </strong>
      <br /> Z wielką chęcią nawiążę współpracę!
    </p>
    <p>
      <strong>
        Zapraszam do kontaktu:
        <br /> zutizuterful@gmail.com <br />
        +48 603 884 830
      </strong>
    </p>
  </div>
)

export default AboutPL
